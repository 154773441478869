import Vue from "vue";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "swiper/css/swiper.min.css";
import "@/utils/rem.js";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// # 在src/main.js文件
import jquery from "jquery";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-dist/jquery-ui.min.css";
Vue.prototype.$ = jquery;

Vue.config.productionTip = false;
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
// 监听路由变化并更新页面标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '固始网';
  next();
})
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
