import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      title: '固始网-首页' //title配置
    },
    component: () => import("../views/index/index.vue"),
  },
  {
    path: "/localcuisine",
    name: "localcuisine",
    meta: {
      title: '固始网-魅力固始' //title配置
    },
    component: () => import("../views/localcuisine/localcuisine.vue"),
  },
  {
    path: "/gourmetdetails/:id",
    name: "gourmetdetails",
    meta: {
      title: '固始网-固始美食' //title配置
    },
    component: () => import("../views/gourmetdetails/gourmetdetails.vue"),
  },
  {
    path: "/gushibeauty/:id",
    name: "gushibeauty",
    meta: {
      title: '固始网-走进固始' //title配置
    },
    component: () => import("../views/gushibeauty/gushibeauty.vue"),
  },
  {
    path: "/gushibeauty",
    name: "gushibeauty",
    meta: {
      title: '固始网-走进固始' //title配置
    },
    component: () => import("../views/gushibeauty/gushibeauty.vue"),
  },
  {
    path: "/scenicdetails/:id",
    name: "scenicdetails",
    meta: {
      title: '固始网-固始风景' //title配置
    },
    component: () => import("../views/scenicdetails/scenicdetails.vue"),
  },
  {
    path: "/charmgushi",
    name: "charmgushi",
    meta: {
      title: '固始网-走进固始' //title配置
    },
    component: () => import("../views/charmgushi/charmgushi.vue"),
  },
  {
    path: "/newsdetail/:id",
    name: "newsdetail",
    meta: {
      title: '固始网-新闻详情' //title配置
    },
    component: () => import("../views/newsdetail/newsdetail.vue"),
  },
  {
    path: "/news/:id/:pid",
    name: "news",
    meta: {
      title: '固始网-新闻列表' //title配置
    },
    component: () => import("../views/news/news.vue"),
  },
  {
    path: "/search",
    name: "search",
    meta: {
      title: '固始网-搜索' //title配置
    },
    component: () => import("../views/search/search.vue"),
  },
  {
    path: "/newsinfo/:id",
    name: "newsinfo",
    meta: {
      title: '固始网-新闻列表' //title配置
    },
    component: () => import("../views/newsinfo/newsinfo.vue"),
  },
  {
    path: "/gushiys/:id",
    name: "gushiys",
    meta: {
      title: '固始网-固始营商' //title配置
    },
    component: () => import("../views/gushiys/gushiys.vue"),
  },
  {
    path: "/password",
    name: "password",
    meta: {
      title: '固始网-登陆' //title配置
    },
    component: () => import("../views/password/password.vue"),
  },
  {
    path: "/user",
    name: "user",
    meta: {
      title: '固始网-个人中心' //title配置
    },
    component: () => import("../views/user/user.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title: '固始网-注册' //title配置
    },
    component: () => import("../views/register/register.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: '固始网-登陆' //title配置
    },
    component: () => import("../views/login/login.vue"),
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
